.onboarding-list .title > h5 {
  font-weight: 700;
  line-height: 23px;
  font-family: "Roboto";
  font-size: 1.3vw;
}
.list-onboarding {
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-bottom: 200px; */
}
#alert-dialog-description {
  font-weight: 700;
  color: black;
}
@media screen and (max-width: 1400px) {
  #abouts
    .css-myhrv0-MuiFormControl-root-MuiTextField-root
    .MuiOutlinedInput-root {
    height: 100px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border-radius: 0px !important;
  }
}
