.general-setting .title > h5 {
  font-weight: 700;
  line-height: 23px;
}
.css-ahj2mt-MuiTypography-root {
  font-weight: 700 !important;
}
.general-setting-button {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: white;
}
.setting-add {
  background: #46a4e3;
  padding: 8px 23px;
  color: white;
}
.general-setting-list .list {
  overflow: scroll;
  padding-bottom: 150px;
  height: 86vh;
}
