.tracking {
  box-shadow: -18px -29px 34px rgba(0, 0, 0, 0.15);
}
.filter label {
  font-weight: 700;
  color: #46a4e3;
}
.partner-details-list {
  background: #fafafa;
  /* z-index: -1; */
  height: 100vh;
}
.partner-details-list .title > h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 38px;
}
.partner-details-list .lists {
  cursor: pointer;
}
.partner-details-list .lists .partner-name {
  font-weight: 700;
  font-weight: 700;
  line-height: 23px;
}
.partner-details-list .lists .partner-locations {
  font-weight: 700;
  font-weight: 700;
  line-height: 23px;
}
.text-yellow {
  color: #f4d010;
}
.text-light-green {
  color: #5eeac8;
}

#active-lists {
  background: #46a4e3 !important;
  box-shadow: -45px 4px 25px rgba(0, 0, 0, 0.05);
}
#active-lists .partner-name {
  color: white !important;
}
#active-lists .partner-locations {
  color: white !important;
}
.Mui-completed .MuiSvgIcon-root {
  color: #82ca89 !important;
}
.MuiStepLabel-label {
  color: #ababab !important;
}
.partner-detail-data > label {
  font-size: 10px;
  font-weight: 700;
  color: #adadad;
}
.partner-detail-data > p {
  margin-bottom: 0;
  font-weight: 700;
}
.tracking .history {
  background-color: #fafafa;
  padding-bottom: 100px !important;
}
.tracking .history .title-history {
  font-size: 12px;
  color: #adadad;
}

.partner-review-button .employee-role {
  width: 150px;
  background: #46a4e3;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  color: white;
}
.partner-review-button .managementRole div .reject {
  padding: 5px 30px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 11px;
  background: #e88b8b;
  color: white;
  font-weight: 700;
  width: max-content;
}
.partner-review-button .managementRole div .agree {
  padding: 5px 30px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 11px;
  background: #82ca89;
  color: white;
  font-weight: 700;
}

.partner-review-button .managementRole div .edit {
  padding: 5px 30px;
  border-radius: 20px;
  cursor: pointer;
  margin-right: 11px;
  background: #000000;
  color: white;
  font-weight: 700;
}
.partner-review-button .edit {
  width: 150px;
  background: #000000;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  color: white;
  padding: 10px;
}
.partner-review-button .managementRole p {
  margin-left: 30px;

  font-weight: 700;
}
.reason-reject > .MuiOutlinedInput-root {
  height: 100px !important;
}
