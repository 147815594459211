.table-edit-icon .edit {
  padding: 2px 6px;
  border: 1px solid gray;
  margin-right: 7px;
  cursor: pointer;
}
.table-edit-icon .add {
  background-color: #46a4e3;
  color: white !important;
  padding: 4px 9px;
  cursor: pointer;
  margin-right: 7px;
}

.table_action_btn {
  min-width: auto !important;
  max-width: 27px !important;
  padding: 2px 6px !important;
  border: 1px solid gray !important;
  margin-right: 7px !important;
  cursor: pointer;
  border-radius: 0px !important;
  &:hover {
    background-color: white !important;
  }
}
