.sap-master .title > h5 {
  font-weight: 700;
  line-height: 23px;
}

.add-branch {
  background: #46a4e3;
  color: white;
  font-weight: 700;
  cursor: pointer;
  padding: 7px 20px;
}
.forms .list .list {
  overflow: scroll;
  height: 80vh;
  background: #fafafa;
}
