.account-onboarding-head .title h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 38px;
}
.account-onboarding {
  padding-bottom: 200px;
}
.account-onboarding-head .clarification-button {
  font-family: "Roboto";
  background: #46a4e3;
  text-align: center;
  width: 294px;
  height: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gst-certificate-view-page {
  border: 2px solid #c4c4c4;
  height: 50px;
  font-family: "Roboto";
  display: flex;
  align-items: center;
  font-weight: 700;
  background-color: #f2f2f2;
  color: #908f8f;
  cursor: pointer;
}
#about {
  height: 80px;
}
@media screen and (max-width: 1400px) {
  #about {
    height: 80px !important;
  }
}
.onboard_main_con {
  width: calc(100% - 39px);
}
