:root {
  --form-height: 500px;
  --line-height: 50px;
  --margin-bottom: 30px;
}

@media screen and (max-height: 810px) {
  .create-password-form {
    --form-height: 400px;
  }
  .create-password-form h2 {
    --line-height: 30px;
  }
  .create-password-form .forms .text-fieald {
    --margin-bottom: 10px;
  }
}

.create_password {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  max-height: 100%;
  bottom: 0;
  left: 0;

  background: linear-gradient(90.17deg, #fff6ed 12.75%, #fff6ed 72.59%);
  /* background: #ffffff; */
}
.create_password .white_space {
  /* position: absolute; */
  width: 100%;
  height: 65vh;

  background: #ffffff;
}
.logo_image {
  width: 100vw !important;
  height: 100vh !important;
}
.login_form {
  position: absolute;
  top: 55.5%;
  left: 83.5%;
  transform: translate(-50%, -50%);
  padding: 6px !important;
  width: 100% !important;
}

#outlined-basic-helper-text {
  margin: 0 !important;
}
.SPRL-white-logo {
  position: relative;
  height: 35vh;
  left: 97px;
}
.white_space .SPRVL-black-log {
  width: 432px;
  height: 276px;
  background: url("../images//SPRVL-Black\ 3.png");
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
  font-family: "Roboto" !important;
}
.css-u78xml-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root:hover {
  background-color: #46a4e3 !important;
}
.desc_feature {
  margin-left: 95px;
}
.desc_feature > h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 38px;
  color: #202020;
  text-transform: capitalize;
}
.desc_feature > p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;

  color: rgba(32, 32, 32, 0.4);
}
.sprl-black-logo {
  box-sizing: border-box;
  height: clamp(100px, 20vh, 210px);
  max-width: 250px;
  /* max-height: 210px; */
  position: absolute;
  top: 0;
  right: 0;
}
.login-button:hover {
  /* color: #000000 !important; */
  background: #46a4e3;
}
.text-fieald-button:hover {
  /* color: #000000 !important; */
  background: #46a4e3;
}
.create-password-form {
  /* margin: auto 12% auto 8%;
  float: right; */
  background: #ffffff;
  padding: 38px;
  /* width: calc(100% - 50px);
  max-width: calc(100% - 50px);
  min-width: calc(100% - 50px); */
  /* box-shadow: 10px 6px 20px 0px #d9cdcd; */
  /* height: var(--form-height); */
  height: 100%;
  width: 30%;
  /* min-height: 20vh;
  height: 300px;
  max-height: 40vh; */
}
.create-password-form h2 {
  font-family: "Roboto";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 500;
  font-size: clamp(4vh, 4vh, 5vh);
  line-height: var(--line-height);
  color: #192a3a;
}
.create-password-form p {
  font-family: "Roboto";
  font-style: normal;
  margin-bottom: clamp(1rem, 3vh, 2rem);
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  margin-top: 1rem;
  color: #000000;
}
.create-password-form .forms .text-fieald {
  width: 428px;
  margin-bottom: var(--margin-bottom);
}
.text-fieald-micro {
  border: 2px solid #c4c4c4;
  height: 57px;
  cursor: pointer;
}
.text-fieald-button {
  width: 428px;
  margin-bottom: 0px;
}
.microsoft-or p {
  margin: 10px 0;
  text-align: center;
}
.create-password-form .forms .text-fieald .microsoft-sso {
  width: 428px;
}
.micro-logo {
  width: 40px;
  margin: 0 10px;
}
.micro-text h4 {
  margin: 0 5px;
  font-family: "Roboto";
}
.input-field > label > span {
  color: red;
}
