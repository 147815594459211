.delete_cancel_btn {
  border: 2px solid #c4c4c4 !important;
  height: 40px !important;
  width: 130px !important;
  justify-content: center !important;
  text-align: center !important;
  display: flex !important;
  font-family: "Roboto" !important;
  font-weight: 700 !important;
  /* font-size: 0.8vw; */
  background-color: #f2f2f2 !important;
  cursor: pointer !important;
  align-items: center !important;
  padding: 0 38px !important;
  margin: 0 34px !important;
  color: #212529 !important;
  font-size: 13px !important;

  &:hover {
    background-color: #f2f2f2 !important;
  }
}
.delete_conf_btn {
  height: 40px !important;
  color: white !important;
  font-family: "Roboto" !important;
  width: 140px !important;
  justify-content: center !important;
  text-align: center !important;
  display: flex !important;
  font-weight: 700 !important;
  cursor: pointer;
  /* font-size: 0.8vw; */
  background-color: #46a4e3 !important;
  align-items: center !important;
  padding: 0 38px !important;
  font-size: 13px !important;

  &:hover {
    background-color: #46a4e3 !important;
  }
}
