#root {
  box-sizing: border-box;
  height: 100vh;
  font-family: "Roboto" !important;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets//font//Roboto//Roboto-Regular.ttf");
  /* src: url("./assets//font//HelveticaFont//Helvetica.ttf"); */
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiOutlinedInput-root {
  border-radius: 0px !important;
  /* border: 2px solid #c4c4c4 !important; */
}
.full-page {
  overflow: hidden;
}
.w-70 {
  width: 70% !important;
}
.w-30 {
  width: 30 !important;
}
.component {
  overflow-y: scroll;
}
code {
  font-family: "Roboto";
}
.global-search {
  width: 380px;
}
#activesidenav {
  background-color: #6ab1ee;
}
#activesubnav {
  color: #00c3ff !important;
}
.bg-darkblue {
  background-color: #0d052b;
}
.w-12 {
  width: 12%;
}
.h-12 {
  height: 12%;
}
.hedears {
  height: 80px;
  z-index: 1;
}
.sidenavbars {
  width: 355px;
  padding-bottom: 100px;
  font-size: 16px;
  background-color: #070626;
  z-index: 4;
}
.sidenav-overflow {
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 100px;
}
.pointer {
  cursor: pointer;
}
.heder-log {
  width: 347px;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}
.heder-avatar {
  width: 2vw !important;
  height: 2vw !important;
}
.heder-setting {
  width: 1.5vw;
  height: 32px;
  margin-right: 40px;
}
.user-name h4 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 21px;
  color: #000000;
  margin: 0;
}
#iconactive svg {
  color: #00c3ff !important;
}
.color-grey {
  color: #bfbfbf !important;
}

/* notification */

.notifications {
  width: 450px;
  /* height: 68vh; */
  box-shadow: 2px 2px 8px black;
  /* border: 1px solid black; */
  position: absolute;
  right: 7px;
  bottom: 7px;
  top: 80px;
  background: white;
  z-index: 5;
}

.notifications .content .avatar {
  background: none;
  color: black;
  border: 1px solid #e0e0e0;
  padding: 17px;
}
.notifications .content p {
  font-size: 12px;
  color: #bfbfbf;
}
.notifications .content h5 {
  margin-bottom: 0;

  font-size: 14px;
}
.notifications .content .values {
  cursor: pointer;
}
#readed {
  color: #bfbfbf !important;
}
@media screen and (max-width: 1400px) {
  .sidenavbars {
    font-size: 13px;
    width: 320px;
    z-index: 4;
  }
  .notifications {
    width: 350px;
  }
  #root {
    font-size: 13px;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    height: 10px !important;
    font-size: 14px !important;
  }
  .css-4k7pa9-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    height: 10px !important;
  }
}
