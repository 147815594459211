.onboarding h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  color: #192a3a;
  margin: 3vh auto 10px 2vw;
  font-size: 1.3vw;
}
.css-1aquho2-MuiTabs-indicator {
  height: 4px !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 54px !important;
  border-radius: 0px !important;
  font-family: "Roboto" !important;
}
.MuiOutlinedInput-root {
  /* padding-right: 35px !important; */
  border-radius: 0px !important;
  /* border: 2px solid #c4c4c4 !important; */
}

.MuiFormControlLabel-label {
  font-size: 0.8vw !important;
}
.add-contact {
  height: 39px;
  background: #46a4e3;
  padding: 10px 32px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
#agreements {
  scroll-behavior: smooth;
}
.scroll {
  float: right;
  cursor: pointer;
  position: sticky;
  top: 48vh;
  margin-right: 10px;
  text-align: end;
  z-index: 3;
}
.input-field label {
  font-weight: 700;
  /* color: #bfbfbf; */
  font-family: "Roboto";
  margin: 12px 0;
  line-height: 16px;
  font-size: 0.8vw;
}
.input-field span {
  font-size: 0.6vw;
  font-family: "Roboto";
  font-weight: 700;
  font-weight: 700;
  line-height: 16px;
}
.msme-certificate {
  border: 2px solid #c4c4c4;
  height: 50px;
  font-family: "Roboto";
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  background-color: #f2f2f2;
  color: #908f8f;
  cursor: pointer;
}
.msme-certificate-require {
  border: 2px solid #d32f2f;
  height: 50px;
  display: flex;
  font-family: "Roboto";
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  color: #908f8f;
  cursor: pointer;
}
/* PANCARD CERTIFICATE */
.pancard_certificate {
  border: 2px solid #c4c4c4;
  height: 50px;
  font-family: "Roboto";
  display: flex;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  background-color: #f2f2f2;
  color: #908f8f;
  cursor: pointer;
}
.pancard_certificate-require {
  border: 2px solid #d32f2f;
  height: 50px;
  display: flex;
  font-family: "Roboto";
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  color: #908f8f;
  cursor: pointer;
}
.botom-navigation {
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.15);
  font-family: "Roboto";
  height: 80px;
  font-weight: 700;
}
.next-and-back {
  padding: 0 3vw;
  font-family: "Roboto";
  display: flex;
}
.next-and-back .back {
  border: 2px solid #c4c4c4;
  height: 40px;
  width: 130px;
  justify-content: center;
  text-align: center;
  display: flex;
  font-family: "Roboto";
  font-weight: 700;
  /* font-size: 0.8vw; */
  background-color: #f2f2f2;
  cursor: pointer;
  align-items: center;
  padding: 0 38px;
  margin: 0 34px;
}
.next-and-back .next {
  height: 40px;
  color: white;
  font-family: "Roboto";
  width: 140px;
  justify-content: center;
  text-align: center;
  display: flex;
  font-weight: 700;
  cursor: pointer;
  /* font-size: 0.8vw; */
  background-color: #46a4e3;
  align-items: center;
  padding: 0 38px;
  /* margin-right: 14px; */
}
.next-and-back .notSubmit {
  height: 40px;

  font-family: "Roboto";
  width: 140px;
  justify-content: center;
  text-align: center;
  display: flex;
  border: 2px solid #c4c4c4;
  font-weight: 700;
  cursor: pointer;
  /* font-size: 0.8vw; */
  background-color: #f2f2f2;
  align-items: center;
  padding: 0 38px;
  /* margin-right: 14px; */
}
.add-more {
  margin-top: 35px;
}
.add-more .add {
  padding: 10px;
  text-align: center;
  background-color: #46a4e3;
  font-weight: 700;
  font-family: "Roboto";
  cursor: pointer;
  color: white;
}
.add-more .cancel {
  padding: 10px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  background: #e4e4e4;
  font-family: "Roboto";
  border: 1px solid #a3a3a3;
}
.set-default-address label {
  font-weight: 700;
  line-height: 23px;
  font-family: "Roboto";
  font-size: 0.9vw;
}
.address-types label {
  font-weight: 700;
  font-size: 0.7vw;
  font-family: "Roboto";
  color: #696969;
}
/* aggrements */

.aggrement {
  border: 1px solid red;
  margin: auto 10px;
  padding: 0 2vw;
  height: 52vh;
  overflow-y: scroll;
}
.body-text {
  margin: 0 0px;
}
.otp-verification {
  /* padding-left: 24px; */
  width: clamp(200px, 20vw, 275px);
}

.otp-verification > h5 {
  font-weight: 700;
  line-height: 22px;
  font-size: 0.9vw;
  text-align: center;
}
.otp-verification .genarate-otp {
  background: #46a4e3;
  padding: 12px 10px;
  cursor: pointer;
  text-align: center;
  color: white;
  font-weight: 700;
  margin-top: 31px;
}
.otp-verification .genarate-otp-grey {
  background: #f2f2f2;
  padding: 12px 10px;

  text-align: center;
  color: black;
  border: 2px solid #c4c4c4;
  font-weight: 700;
  margin-top: 31px;
}
.otp-verification .genarate-otp-green {
  background: #82ca89;
  padding: 12px 10px;

  text-align: center;
  color: white;

  font-weight: 700;
  margin-top: 31px;
}
.verify-otp .otp-sent {
  font-size: 0.7vw;
  font-weight: 700;
  color: #838181;
}
.verify-otp .otp-enter-text {
  font-weight: 700;
  line-height: 23px;
  color: #838181;
}
.tic-icon {
  font-size: 0.9vw;
  color: #82ca89;
  margin: 0 7px;
}
.otp-field .input-otp {
  width: 47px;
  margin-right: 27px;
  height: 47px;
  background-color: #f8f8f8;
  border: 2px solid #bfbfbf;
  outline: none;
  font-size: 26px;
  font-family: "Roboto";
  text-align: center;
}
.otp-field .last {
  width: 47px;
  margin-right: 0 !important;
  height: 47px;
  background-color: #f8f8f8;
  border: 2px solid #bfbfbf;
  outline: none;
  font-size: 26px;
  font-family: "Roboto";
  text-align: center;
}

.add-onboarding-buttons {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 3;
}

/* media query */
@media screen and (max-width: 1400px) {
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    height: 43px !important;
    border-radius: 0px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .css-f0hq3i .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height: 10px !important;
  }
  .css-nthq35 .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    height: 10px !important;
  }
  .css-4k7pa9-MuiAutocomplete-root
    .MuiOutlinedInput-root
    .MuiAutocomplete-input {
    height: 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .css-4gb8zz-MuiAutocomplete-root .MuiAutocomplete-input {
    height: 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .css-1digwxr-MuiAutocomplete-root .MuiAutocomplete-input {
    height: 10px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .css-1kombhq-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 44px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
  }
  .country_code > fieldset {
    height: 44px !important;
  }
  .country_code > div > fieldset {
    height: 44px !important;
  }
  /* .country_code > div > div {
    left: 40px !important;
    top: calc(50% - 13px);
  }
  .country_code > div > button {
    padding: 0 !important;
  } */
  .otp-verification {
    /* padding-left: 24px; */
    width: clamp(200px, 10vw, 275px);
  }
  .country_code {
    height: 44px !important;
  }
  .MuiOutlinedInput-root {
    height: 44px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    border-radius: 0px !important;
  }

  .msme-certificate {
    height: 44px;
  }
  .pancard_certificate {
    height: 44px;
  }
}
